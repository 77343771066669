<template>
  <div>
    <div class="top">
      <img src="../../../../static/image/left.png" @click="back" alt="" />
    </div>

    <div class="verification">验证银行预留手机号</div>
    <div>
      <div class="list_box"><span>手机号</span><span v-text="tel"></span></div>
      <div class="list_box">
        <span>验证码</span
        ><input
          type="text"
          @input="specifiName($event)"
          v-model="verification"
          placeholder="填写验证码"
        />
        <div>已发送<span>（59）</span></div>
      </div>
    </div>

    <div class="next" @click="next">下一步</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flowId: '',
      tel: '',
      verification: '',
    };
  },
  methods: {
    // 监听
    specifiName(e) {
      // var that = this;
      var val = e.target.value;
      if (val.length == 6) {
        let anniu = document.querySelector('.next');
        anniu.style.color = '#FFFFFF';
        anniu.style.backgroundColor = '#0754D3';
      } else if (val.length <= 6) {
        let anniu = document.querySelector('.next');
        anniu.style.color = '#666666';
        this.$toast.fail('请输入正确验证码');

        anniu.style.backgroundColor = '#f8f8f8';
      }
    },
    next() {
      if (this.verification == '') {
        this.$toast.fail('请输入验证码');
      }

      let params = {
        flowId: this.flowId,
        authcode: this.verification,
        token: localStorage.getItem('token'),
      };
      this.$api.anyVerifyBankcard(params).then((res) => {
        if (res.code == 0) {
          this.$router.push('/win');
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    ///返回上一页
    back() {
      this.$router.back();
    },
  },
  mounted() {
    this.flowId = this.$route.query.flowId;
    this.tel = this.$route.query.num.tel;
  },
};
</script>
<style lang="less" scoped>
.next {
  width: 177px;
  height: 45px;
  background-color: #f8f8f8;
  color: #666666;
  text-align: center;
  line-height: 45px;
  font-size: 15px;
  margin: 0 auto;
  margin-top: 50px;
}
.verification {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 40px;
  margin-top: 44px;
}
.list_box {
  display: flex;
  align-items: center;
  margin-left: 34px;
  margin-right: 32px;
  border-bottom: 1px #f8f8f8 solid;
  height: 55px;
  div {
    font-size: 13px;
    display: flex;
    color: #c1c1c1;
  }
  span {
    font-size: 15px;
    display: block;
    width: 60px;
  }
  input {
    font-size: 14px;
    border: 0;
    width: 150px;
  }
}
.top {
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 21px;
  background-color: #ffffff;
  img {
    width: 11px;
    height: 20px;
  }
}
</style>
